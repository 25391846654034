import React, { Component } from "react";
import { Image, Layer, Stage, Text, Rect } from "react-konva";
import Cropper from "react-easy-crop";
import { Button, Modal, Row, Col } from "antd";
import staticImage from "./FRAME.png";
import testImage from "./test.jpg";

const styles = {
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    backgroundColor: '#186575', // Change to the desired color
    padding: '10px 0 20px 0', // Adjust for desired height
  },
  headerText: {
    fontSize: '1.5em', // Adjust for desired font size
    color: '#ffffff' // Change to the desired color
  }
};
class App extends Component {
  fileInputRef = React.createRef();
  stageRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      uploadedImage: null,
      inputText: "",
      staticPhoto: null,
      crop: { x: 0, y: 0 },
      zoom: 1,
      croppedAreaPixels: null,
      croppingImage: null,
      isModalVisible: false,
    };
  }

  componentDidMount() {
    const image = new window.Image();
    image.src = staticImage;
    const img = new window.Image();
    img.src = testImage;
    // image.crossOrigin = "Anonymous";
    image.onload = () => {
      this.setState({
        staticPhoto: image,
        // uploadedImage: img
      });
    };
  }

  // handleImageUpload = (event) => {
  //   const file = event.target.files[0];
  //   const reader = new FileReader();

  //   reader.onloadend = () => {
  //     const uploadedImage = new window.Image();
  //     uploadedImage.src = reader.result;
  //     uploadedImage.onload = () => {
  //       this.setState({
  //         uploadedImage: uploadedImage
  //       });
  //     };
  //   };

  //   if (file) {
  //     reader.readAsDataURL(file);
  //   }
  // };

  handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.addEventListener(
      "load",
      () => {
        this.setState({ croppingImage: reader.result, isModalVisible: true });
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  handleCancel = () => {
    this.setState({
      crop: { x: 0, y: 0 },
      zoom: 1,
      croppedAreaPixels: null,
      croppingImage: null,
    });
  };

  handleOnCropComplete = async () => {
    const croppedImage = await this.getCroppedImg(
      this.state.croppingImage,
      this.state.croppedAreaPixels
    );
    const uploadedImage = new window.Image();
    uploadedImage.src = croppedImage;
    uploadedImage.onload = () => {
      this.setState({
        uploadedImage: uploadedImage,
      });
    };
    this.setState({
      uploadedImage: uploadedImage,
      croppingImage: null,
      isModalVisible: false,
    });
  };

  getCroppedImg = (imageSrc, crop) => {
    return new Promise((resolve) => {
      const image = new window.Image();
      image.src = imageSrc;
      image.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        canvas.width = crop.width;
        canvas.height = crop.height;

        ctx.drawImage(
          image,
          crop.x,
          crop.y,
          crop.width,
          crop.height,
          0,
          0,
          crop.width,
          crop.height
        );
        resolve(canvas.toDataURL());
      };
    });
  };

  handleTextChange = (event) => {
    this.setState({ inputText: event.target.value.toUpperCase() });
  };

  handleButtonClick = () => {
    if (this.fileInputRef.current) {
      this.fileInputRef.current.click();
    }
  };

  handleDownload = () => {
    // Convert the stage to a data URL
    // const canvas =
    //   document.getElementsByClassName("canvas")[0].childNodes[0].childNodes[0];
    const dataUrl = this.stageRef.current.toDataURL();

    // Create a new anchor element
    const link = document.createElement("a");

    // Set the href and download attributes of the anchor
    link.href = dataUrl;
    link.download = `ppcl_${this.state.inputText}.png`;

    // Append the anchor to the body
    document.body.appendChild(link);

    // Simulate a click on the anchor
    link.click();

    // Remove the anchor from the body
    document.body.removeChild(link);
  };

  render() {
    const {
      staticPhoto,
      uploadedImage,
      inputText,
      crop,
      zoom,
      croppingImage,
      isModalVisible,
    } = this.state;
    const textWidth = 500;
    const textX = 400;
    const textY = 1124;

    return (
      <div>
        <div style={styles.header}>
          <h1 style={styles.headerText}>PANDAV PARIVAR CRICKET LEAGUE</h1>
        </div>

        <Row justify="center" style={{ display: "none" }}>
          <Col xs={24} sm={12}>
            <Stage
              className="canvas"
              width={1280}
              height={1536}
              ref={this.stageRef}
            >
              <Layer>
                {uploadedImage && (
                  <Image
                    image={uploadedImage}
                    x={490}
                    y={625}
                    height={460}
                    width={320}
                  />
                )}
                <Image image={staticPhoto} x={0} y={0} height={1536}
                  width={1280}/>
                <Rect // this is your placeholder
                  x={textX}
                  y={textY}
                  width={textWidth}
                  height={50}
                />
                <Text
                  text={inputText}
                  x={textX}
                  y={textY + 19}
                  width={textWidth}
                  fontSize={39}
                  fontFamily="Roboto"
                  fontColor='yellow'
                  fontStyle="bold"
                  fill="white"
                  align="center"
                  verticalAlign="middle"
                  wrap="none"
                />
              </Layer>
            </Stage>
          </Col>
        </Row>

        <Row justify="center">
          <Col
            xs={24}
            sm={6}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Stage width={400} height={480}>
              <Layer>
                {uploadedImage && (
                  <Image
                    image={uploadedImage}
                    x={153}
                    y={195}
                    height={145}
                    width={100}
                  />
                )}
                <Image
                  image={staticPhoto}
                  x={0}
                  y={0}
                  height={480}
                  width={400}
                />
                <Rect // this is your placeholder
                  x={235}
                  y={265}
                  width={130}
                  height={32}
                  // fill={'red'}
                />
                <Text
                  text={inputText}
                  x={125}
                  y={346 + 12}
                  width={155}
                  fontSize={12.2}
                  fontFamily="Roboto"
                  fontStyle="bold"
                  fill="white"
                  align="center"
                  verticalAlign="middle"
                  wrap="none"
                />
              </Layer>
            </Stage>
          </Col>
        </Row>

        <Row justify="center">
          <Col
            xs={24}
            sm={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <input
              type="file"
              id="file"
              onChange={this.handleImageUpload}
              style={{ display: "none" }}
              ref={this.fileInputRef}
            />
            <Button type="primary" onClick={this.handleButtonClick}>
              Select Image
            </Button>
          </Col>
        </Row>

        <Row justify="center">
          <Col
            xs={24}
            sm={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <input
              type="text"
              maxLength={22}
              onChange={this.handleTextChange}
              placeholder="Enter your name..."
              style={{
                display: 'inline-block',
                padding: '0.375rem 0.75rem',
                fontSize: '1rem',
                fontWeight: '400',
                lineHeight: '1.5',
                color: '#495057',
                backgroundColor: '#fff',
                backgroundClip: 'padding-box',
                border: '1px solid #ced4da',
                borderRadius: '0.25rem',
                transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out'
              }}             
            />
          </Col>
        </Row>

        <Row justify="center">
          <Col
            xs={24}
            sm={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button type="primary" onClick={this.handleDownload}>
              Download
            </Button>
          </Col>
        </Row>

        <Modal
          visible={isModalVisible}
          onCancel={() => this.setState({ isModalVisible: false })}
          onOk={this.handleOnCropComplete}
          bodyStyle={{
            height: "500px",
            width: "100%",
            overflow: "auto",
            padding: "24px",
          }}
          centered
        >
          {croppingImage && (
            <div
              style={{
                height: "calc(100% - 48px)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Cropper
                image={croppingImage}
                crop={crop}
                zoom={zoom}
                aspect={0.83}
                onCropChange={(newCrop) => this.setState({ crop: newCrop })}
                onCropComplete={(croppedArea, croppedAreaPixels) =>
                  this.setState({ croppedAreaPixels })
                }
                onZoomChange={(newZoom) => this.setState({ zoom: newZoom })}
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </div>
          )}
        </Modal>
      </div>
    );
  }
}

export default App;
